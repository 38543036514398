// Gatsby supports TypeScript natively!
import React, { useRef } from "react"
import { PageProps, Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';


import AddIcon from '@material-ui/icons/Add'

const useStyles = makeStyles((theme) => ({
  title: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '34px !important',
      lineHeight: 'initial !important',
      padding: '12px !important'
    }
  }
}));

type Data = {
  site: {
    siteMetadata: {
      title: string
    }
  }
  allMarkdownRemark: {
    edges: {
      node: {
        excerpt: string
        frontmatter: {
          title: string
          date: string
          description: string
        }
        fields: {
          slug: string
        }
      }
    }[]
  }
}

const BlogIndex = ({ data, location }: PageProps<Data>) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges
  const classes = useStyles();

  return (
        <Layout location={location} title={siteTitle}>
          {/* <div style={{width: 400, height: 400, background: `url(${data.hero_image.childImageSharp.fixed})`}}></div> */}
          <SEO title="Dev Internship Dotcom - Pricing" />
          <h2>Pricing</h2>
          <div>Our vision for Dev Internship Dotcom is to provide a real internship like experience working with modern technologies on real products to as many people as possible. We feel strongly it's a way to deliver a high amount of value, to help people grow and accomplish there goals. 
            <br/><br/>Helping people has always been important to us and we've always been frustrated at not being able to offer internships/jobs to everyone we would have liked to in our careers. But with the power of Dev Internship Dotcom, we can now extend our ability to develop people to anyone who is looking to take a step forward.
            <br/><br/>We realize that not everyone has the means of investing in themselves, so we've opened up most ofour videos that we've spent countless hours working on, for free. If you're someone who truly would benefit from the premium experience and cannot afford it please reach out to us directly at ci@devinternship.com and we'll see what we can do.
            </div>
       
          <TableContainer component={Paper} style={{marginTop: 24, backgroundColor: 'white !important'}}>
      <Table>
        <TableHead>
          <TableRow style={{height: 150}}>
            <TableCell align="center">
              <div  className={classes.title} style={{fontSize: '72px', padding: 24, color: 'rgb(81, 37, 94)'}}>Free</div>
              <div>Limited Videos</div>
              </TableCell>
            <TableCell align="center">
              <div className={classes.title} style={{fontSize: '72px', padding: 24, color: 'rgb(200, 81, 75)'}}>$99</div>
              <div>Immersive Experience</div></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            <TableRow>
              <TableCell  align="center" component="th" scope="row">
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                  <AddIcon style={{color: 'rgb(58, 202, 227)', marginRight: 4}}/> Standard Videos
                </div>
              </TableCell>
              <TableCell align="center">
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                  <AddIcon style={{color: 'rgb(58, 202, 227)', marginRight: 4}}/> Standard Videos
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell  align="center" component="th" scope="row">
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                  <AddIcon style={{color: 'rgb(58, 202, 227)', marginRight: 4}}/> Business Advice
                </div>
              </TableCell>
              <TableCell align="center">
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                  <AddIcon style={{color: 'rgb(58, 202, 227)', marginRight: 4}}/> Business Advice
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell  align="center" component="th" scope="row">
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                  <AddIcon style={{color: 'rgb(58, 202, 227)', marginRight: 4}}/> Career Advice
                </div>
              </TableCell>
              <TableCell align="center">
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                  <AddIcon style={{color: 'rgb(58, 202, 227)', marginRight: 4}}/> Career Videos
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell  align="center" component="th" scope="row">
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                  <AddIcon style={{color: 'rgb(58, 202, 227)', marginRight: 4}}/> Best Wishes
                </div>
              </TableCell>
              <TableCell align="center">
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                  <AddIcon style={{color: 'rgb(58, 202, 227)', marginRight: 4}}/> Best Wishes
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell  align="center" component="th" scope="row">
              </TableCell>
              <TableCell align="center">
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                  <AddIcon style={{color: 'rgb(58, 202, 227)', marginRight: 4}}/> Code Repo Access
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell  align="center" component="th" scope="row">
              </TableCell>
              <TableCell align="center">
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                  <AddIcon style={{color: 'rgb(58, 202, 227)', marginRight: 4}}/> Work Email
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell  align="center" component="th" scope="row">
              </TableCell>
              <TableCell align="center">
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                  <AddIcon style={{color: 'rgb(58, 202, 227)', marginRight: 4}}/> Immersive Internship
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell  align="center" component="th" scope="row">
              </TableCell>
              <TableCell align="center">
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                  <AddIcon style={{color: 'rgb(58, 202, 227)', marginRight: 4}}/> Bonus Videos
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell  align="center" component="th" scope="row">
              </TableCell>
              <TableCell align="center">
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                  <AddIcon style={{color: 'rgb(58, 202, 227)', marginRight: 4}}/> Discount to Future Internships
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell  align="center" component="th" scope="row">
              </TableCell>
              <TableCell align="center">
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                  <AddIcon style={{color: 'rgb(58, 202, 227)', marginRight: 4}}/> Community Access (when it launches)
                </div>
              </TableCell>
            </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
       
        </Layout>

  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
